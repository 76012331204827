<template>
  <div class="resume" id="resume">
    <div class="area">
      <a-row
        type="flex"
        justify="space-between"
        style="font-size:16px;color:#333333;"
      >
        <a-col :span="8">
          <span class="border-r zIndex" style="padding:0px 10px;"
            >原始简历</span
          >
        </a-col>
        <a-col :span="4" style="text-align:right;">
          <!-- <span class="zIndex"> 
                        <i class="iconfont icon-bianji"  style="margin-right:10px;"></i>
                        <a-icon type="down" />
                        <a-icon type="up" />
                    </span> -->
        </a-col>
      </a-row>
      <div class="flexBox" style="margin-top:10px;">
        <div class="flexLeft">
          <span style="padding: 0px 14px;">附件简历</span>
        </div>
        <div style="width:100%;">
          <div v-for="(list, k) in resume_arr" :key="k">
            <div class="lineHeight">
              <span class="fileName" v-text="list.FileName"></span>
              <i
                class="iconfont icon-shanchu-"
                @click="CVDelFn(list.Id, k)"
              ></i>
              <i
                class="iconfont icon-yulan"
                @click="CVShowFn(list.Id)"
                style="margin-left:10px;"
              ></i>
              <a
                class="iconfont icon-xiazai-"
                :href="list.FileUrl + '?attname=' + list.FileName"
                style="margin-left:10px;"
              ></a>
            </div>
            <div
              class="ctrlView"
              style="height:500px;"
              v-if="CV_arr_show.indexOf(list.Id) > 0"
            >
              <a-icon
                type="close-circle"
                class="closeBtn"
                @click="CVHideFn(list.Id)"
              />
              <!-- .doc,.docx,.txt???,.rtf,.wps, -->
              <iframe
                v-if="list.FileType == 'world'"
                class="lookView"
                :src="
                  'https://view.officeapps.live.com/op/view.aspx?src=' +
                    list.FileUrl
                "
                width="100%"
                height="100%"
                frameborder="0"
              ></iframe>
              <iframe
                v-if="list.FileType == 'pdf'"
                class="lookView"
                :src="list.FileUrl"
                width="100%"
                height="100%"
                frameborder="0"
              ></iframe>
              <div v-if="list.FileType == 'image'">
                <img
                  :src="list.FileUrl + '?imageView2/2/h/500'"
                  style="margin: 20px 50% 0px;transform: translateX(-50%);"
                />
              </div>
            </div>
          </div>

          <div style="margin-top: 20px;">
            <a-upload
              :fileList="[]"
              :remove="handleRemove"
              :beforeUpload="beforeUpload"
            >
              <a-button class="border ant-menu-item-selected ">
                上传简历</a-button
              >
            </a-upload>
          </div>
        </div>
      </div>
    </div>

    <div class="area">
      <a-row
        type="flex"
        justify="space-between"
        class="liner"
        style="margin:20px 0px;font-size:16px;color:#333333;"
      >
        <a-col :span="8">
          <span class="border-r zIndex" style="padding:0px 10px;"
            >标准简历</span
          >
        </a-col>
        <a-col :span="4" style="text-align:right;">
          <span class="zIndex" style="color:#999;font-size:14px;">
            <!-- <a-icon type="form" /> -->
            <i
              class="iconfont icon-bianji"
              style="margin-right:10px;"
              v-if="showBool[0].operation"
            ></i>
            <a-icon
              :type="showBool[0].bool ? 'down' : 'up'"
              @click="showFn(0)"
            />
          </span>
        </a-col>
      </a-row>
      <div style="margin:10px auto;width:90%;" v-if="showBool[0].bool">
        <a-form>
          <a-row :gutter="16">
            <a-col class="gutter-row" :xl="{ span: 24 }" :md="{ span: 24 }">
              <div class="title">
                {{ Name }}
                <span
                  class="iconSex bgGlob"
                  :style="{ background: Sex == 1 ? '#FF4081' : '#13C2C2' }"
                >
                  <a-icon v-if="Sex == '1'" type="woman" />
                  <a-icon type="man" v-else />
                </span>
              </div>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="手机">
                <a-input
                  type="number"
                  v-model="phone"
                  maxLength="11"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="婚姻状况">
                <a-radio-group
                  :options="options_Marriage"
                  @change="onMarriageChange"
                  v-model="Marriage"
                />
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="邮箱">
                <a-input type="text" v-model="email" placeholder="请输入" />
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="现居地">
                <a-input
                  type="text"
                  v-model="city"
                  placeholder="请输入"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="生日">
                <a-date-picker
                  style="width:100%;"
                  :showToday="false"
                  @change="birthdayChange"
                  :defaultValue="moment(birthday, 'YYYY-MM-DD')"
                  placeholder="请选择"
                  format="YYYY-MM-DD"
                  :disabledDate="disabledDate"
                />
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="应聘职位">
                <a-input
                  type="text"
                  v-model="posts"
                  placeholder="请输入"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="籍贯">
                <a-select
                  showSearch
                  placeholder="请选择"
                  optionFilterProp="children"
                  :filterOption="filterOption"
                  v-model="province"
                >
                  <a-select-option
                    v-for="(item, k) in arr_province"
                    :key="k"
                    :value="item"
                    >{{ item }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="来源渠道">
                <a-select
                  v-model="source"
                  showSearch
                  @change="handleChange"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="(item, k) in arr_source"
                    :key="k"
                    :value="item"
                    >{{ item }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="学历">
                <a-select
                  v-model="Education"
                  showSearch
                  @change="handleChange"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="(item, k) in arr_Education"
                    :key="k"
                    :value="item.value"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="专业">
                <a-input
                  type="text"
                  v-model="speciality"
                  placeholder="请输入"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="工作经验">
                <a-select
                  v-model="experience"
                  showSearch
                  @change="handleChange"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="(item, k) in arr_experience"
                    :key="k"
                    :value="item"
                    >{{ item }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="毕业学校">
                <a-input
                  type="text"
                  v-model="school"
                  placeholder="请输入"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
              <a-form-item v-bind="formItemLayout" label="录入人:"
                >{{ CreateUserName }}
              </a-form-item>
            </a-col>
            <!-- <a-col class="gutter-row" :xl="{span:12}" :md="{span:24}">
                            <a-form-item v-bind="formItemLayout" 
                                label="录入时间:"
                            >{{CreateUserName}}
                            </a-form-item>
                        </a-col> -->
          </a-row>
        </a-form>
        <!--求职意向  -->
        <div class="area">
          <a-row type="flex" justify="space-between">
            <a-col :span="8">
              <span class="border-r zIndex" style="padding:0px 10px;"
                >求职意向</span
              >
            </a-col>
          </a-row>
          <a-form>
            <a-row>
              <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
                <a-form-item v-bind="formItemLayout" label="工作地址:">
                  <a-input
                    type="text"
                    v-model="address"
                    placeholder="请输入"
                  ></a-input>
                </a-form-item>
              </a-col>
              <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
                <a-form-item v-bind="formItemLayout" label="到岗时间:">
                  <a-select
                    v-model="worktime"
                    showSearch
                    @change="handleChange"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="(item, k) in arr_worktime"
                      :key="k"
                      :value="item"
                      >{{ item }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
                <a-form-item v-bind="formItemLayout" label="期望薪资:">
                  <a-input
                    type="text"
                    v-model="money"
                    placeholder="请输入"
                  ></a-input>
                </a-form-item>
              </a-col>
              <a-col class="gutter-row" :xl="{ span: 12 }" :md="{ span: 24 }">
                <a-form-item v-bind="formItemLayout" label="行业:">
                  <a-input
                    type="text"
                    v-model="typeClass"
                    placeholder="请输入"
                  ></a-input>
                </a-form-item>
              </a-col>
            </a-row>
            <div style="margin:10px;text-align:center;">
              <a-button type="dashed" @click="ajaxCVfn()">
                <a-icon type="plus" />重 置
              </a-button>
              <a-button
                type="primary"
                @click="subCVBtn()"
                style="margin-left:20px;"
              >
                保 存
              </a-button>
            </div>
          </a-form>
        </div>
        <!-- 工作经历 -->
        <div class="area">
          <a-row type="flex" justify="space-between">
            <a-col :span="8">
              <span class="border-r zIndex" style="padding:0px 10px;"
                >工作经历</span
              >
            </a-col>
          </a-row>
          <a-form>
            <div v-for="(item, k) in workInfo_obj" :key="k">
              <div class="liner flexBox" style="align-items: center;">
                <span
                  class="zIndex"
                  style="padding-left: 6%;min-width:136px;margin:12px 0px; display: inline-block;"
                  >工作经历{{ numberArr[k] }}</span
                >
                <span style="padding:2px 6px;background:#ffffff;z-index: 10;">
                  <i
                    class="iconfont icon-bianji"
                    style="margin-right:10px;"
                    @click="item.bool = !item.bool"
                  ></i>
                  <i
                    class="iconfont icon-shanchu-"
                    @click="removeDel(k, item.Id)"
                  >
                  </i>
                </span>
              </div>
              <a-form-item
                v-bind="formItemLayout2"
                :label="'公司名称'"
                style="width:50%;display:inline-block;"
              >
                <a-input
                  v-model="item.CompanyName"
                  :disabled="item.bool"
                  @input="changeInput"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout2"
                :label="'任职岗位'"
                style="width:50%;display:inline-block;"
              >
                <a-input
                  v-model="item.Position"
                  :disabled="item.bool"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout2"
                :label="'起止时间'"
                style="width:50%;display:inline-block;"
              >
                <a-range-picker
                  format="YYYY-MM-DD"
                  :defaultValue="[
                    moment(item.StartDate, 'YYYY-MM-DD'),
                    moment(item.EndDate, 'YYYY-MM-DD')
                  ]"
                  :placeholder="['开始时间', '结束时间']"
                  allowClear
                  :disabled="item.bool"
                  @change="workChangeTime($event, k)"
                />
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout2"
                :label="'职业描述'"
                style="width:50%;display:inline-block;"
              >
                <a-textarea
                  v-model="item.Intro"
                  :disabled="item.bool"
                  placeholder="请输入"
                ></a-textarea>
              </a-form-item>
              <div style="margin:10px;text-align:center;" v-show="!item.bool">
                <a-button type="dashed" @click="cancelFn(k, 'work')"
                  >取消</a-button
                >
                <a-button
                  type="primary"
                  @click="changeWork(k)"
                  style="margin-left:20px;"
                >
                  保 存
                </a-button>
              </div>
            </div>
          </a-form>
          <a-form>
            <div class="liner">
              <span class="zIndex addtitle">添加工作经历</span>
            </div>
            <!-- <a-divider>添加工作经历</a-divider> -->
            <a-form-item
              v-bind="formItemLayout2"
              :label="'公司名称'"
              style="width:50%;display:inline-block;"
            >
              <a-input v-model="workNames" placeholder="请输入" />
            </a-form-item>

            <a-form-item
              v-bind="formItemLayout2"
              :label="'任职岗位'"
              style="width:50%;display:inline-block;"
            >
              <a-input v-model="posts_Work" placeholder="请输入" />
            </a-form-item>
            <a-form-item
              v-bind="formItemLayout2"
              :label="'起止时间'"
              style="width:50%;display:inline-block;"
            >
              <a-range-picker
                format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"
                allowClear
                style="width:100%"
                @change="workChange"
                :value="workDate"
              />
            </a-form-item>
            <a-form-item
              v-bind="formItemLayout2"
              :label="'职业描述'"
              style="width:50%;display:inline-block;"
            >
              <a-textarea v-model="Remark" placeholder="请输入"></a-textarea>
            </a-form-item>

            <div style="margin:10px;text-align:center;">
              <a-button
                type="primary"
                @click="workSubmit"
                style="margin-left:20px;"
              >
                保 存
              </a-button>
            </div>
          </a-form>
        </div>
        <!-- 项目经验 -->
        <div class="area">
          <a-row type="flex" justify="space-between">
            <a-col :span="8">
              <span class="border-r zIndex" style="padding:0px 10px;"
                >项目经验</span
              >
            </a-col>
          </a-row>
          <a-form>
            <div v-for="(item, k) in projectinfo_obj" :key="k">
              <div class="liner flexBox" style="align-items: center;">
                <span
                  class="zIndex"
                  style="padding-left: 6%;min-width:100px;margin:12px 0px; display: inline-block;"
                  >项目{{ numberArr[k] }}
                </span>
                <span style="padding:2px 6px;background:#ffffff;z-index: 10;">
                  <i
                    class="iconfont icon-bianji"
                    style="margin-right:10px;"
                    @click="item.bool = !item.bool"
                  ></i>
                  <i
                    class="iconfont icon-shanchu-"
                    @click="removeDel(k, item.Id, 'project')"
                  >
                  </i>
                </span>
              </div>
              <a-form-item
                v-bind="formItemLayout2"
                :label="'项目名称'"
                style="width:50%;display:inline-block;"
              >
                <a-input
                  v-model="item.ProjectName"
                  :disabled="item.bool"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout2"
                :label="'公司名称'"
                style="width:50%;display:inline-block;"
              >
                <a-input
                  v-model="item.CompanyName"
                  :disabled="item.bool"
                  placeholder="请输入"
                />
              </a-form-item>

              <a-form-item
                v-bind="formItemLayout2"
                :label="'任职岗位'"
                style="width:50%;display:inline-block;"
              >
                <a-input
                  v-model="item.Position"
                  :disabled="item.bool"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout2"
                :label="'起止时间'"
                style="width:50%;display:inline-block;"
              >
                <a-range-picker
                  format="YYYY-MM-DD"
                  :defaultValue="[
                    moment(item.StartDate, 'YYYY-MM-DD'),
                    moment(item.EndDate, 'YYYY-MM-DD')
                  ]"
                  :placeholder="['开始时间', '结束时间']"
                  allowClear
                  :disabled="item.bool"
                  @change="projectChangeTime($event, k)"
                />
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout2"
                :label="'项目描述'"
                style="width:50%;display:inline-block;"
              >
                <a-textarea
                  v-model="item.Intro"
                  :disabled="item.bool"
                  placeholder="请输入"
                ></a-textarea>
              </a-form-item>
              <div style="margin:10px;text-align:center;" v-if="!item.bool">
                <a-button type="dashed" @click="cancelFn(k)">取消</a-button>
                <a-button
                  type="primary"
                  @click="changeProject(k)"
                  style="margin-left:20px;"
                >
                  保 存
                </a-button>
              </div>
            </div>
          </a-form>
          <a-form>
            <div class="liner">
              <span class="zIndex addtitle">添加项目经验</span>
            </div>
            <!-- <a-divider>添加项目经验</a-divider> -->
            <a-form-item
              v-bind="formItemLayout2"
              :label="'项目名称'"
              style="width:50%;display:inline-block;"
            >
              <a-input v-model="ProjectName" placeholder="请输入" />
            </a-form-item>
            <a-form-item
              v-bind="formItemLayout2"
              :label="'公司名称'"
              style="width:50%;display:inline-block;"
            >
              <a-input v-model="CompanyNames" placeholder="请输入" />
            </a-form-item>
            <a-form-item
              v-bind="formItemLayout2"
              :label="'任职岗位'"
              style="width:50%;display:inline-block;"
            >
              <a-input v-model="position" placeholder="请输入" />
            </a-form-item>
            <a-form-item
              v-bind="formItemLayout2"
              :label="'起止时间'"
              style="width:50%;display:inline-block;"
            >
              <a-range-picker
                format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"
                allowClear
                style="width:100%"
                @change="projectChange"
                :value="timeRanges"
              />
            </a-form-item>
            <a-form-item
              v-bind="formItemLayout2"
              :label="'项目描述'"
              style="width:50%;display:inline-block;"
            >
              <a-textarea v-model="Intro" placeholder="请输入"></a-textarea>
            </a-form-item>
            <div style="margin:10px;text-align:center;">
              <a-button
                type="primary"
                @click="projectSubmit()"
                style="margin-left:20px;"
              >
                保 存
              </a-button>
            </div>
          </a-form>
        </div>
      </div>
      <!--  -->
      <a-row
        type="flex"
        justify="space-between"
        class="liner"
        style="margin:20px 0px;font-size:16px;color:#333333;"
      >
        <a-col :span="8">
          <span class="border-r zIndex" style="padding:0px 10px;"
            >相关资料</span
          >
        </a-col>
        <a-col :span="4" style="text-align:right;">
          <span class="zIndex" style="color:#999;font-size:14px;">
            <!-- <a-icon type="form" /> -->
            <i
              class="iconfont icon-bianji"
              v-if="showBool[1].operation"
              style="margin-right:10px;"
            ></i>
            <a-icon
              :type="showBool[1].bool ? 'down' : 'up'"
              @click="showFn(1)"
            />
          </span>
        </a-col>
      </a-row>
    </div>
    <a-spin style="position:fixed;left:50%;top:150px" v-if="spinning">
      <a-icon
        slot="indicator"
        type="loading"
        :spinning="spinning"
        style="font-size: 44px;color:red;"
        spin
      />
    </a-spin>
  </div>
</template>
<script>
import moment from 'moment'
import {
  __options_Marriage,
  __arr_experience,
  __arr_Education,
  __arr_worktime,
  __arr_province,
  __dateTransation
} from '../../../../assets/js/public_ren'
let WorkId = 0 //动态添加工作经验用
let ProjectId = 0
function indexOfFn (val) {
  let index = val.lastIndexOf('.')
  let last_value = val.substring(index + 1)
  return last_value
}

//判断文件类型格式
function __fileUpPublicFn (list) {
  let file_state = 0
  let value = indexOfFn(list.FileName)
  if (list.FileType.indexOf('image/') == 0) {
    file_state = 'image'
  } else if (list.FileType.indexOf('application/') == 0) {
    let str = 'docx,pptx,xlsx,rtf,ppt,xls,doc'
    if (str.indexOf(value) != -1) {
      file_state = 'world'
    } else if (value == 'pdf') {
      file_state = 'pdf'
    } else {
    }
  }
  return file_state
}
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'resume',
  data () {
    return {
      spinning: false,
      showBool: [
        { operation: true, bool: true },
        { operation: false, bool: true }
      ],
      dateFormat: 'YYYY-MM-DD',
      numberArr: ['一', '二', '三', '四', '五', '六', '七', '八', '九'],
      formItemLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      },
      formItemLayout2: {
        labelCol: {
          md: { span: 24 },
          xl: { span: 7 }
        },
        wrapperCol: {
          md: { span: 24 },
          xl: { span: 17 }
        }
      },
      workInfo: [], //工作信息
      workInfo_obj: [],
      //项目经验
      projectinfo_obj: [],

      Id: '', //简历id
      CV_arr_show: [0],

      options_Marriage: __options_Marriage(), //[
      //     { label: '未婚', value: '未婚' },
      //     { label: '已婚', value: '已婚' },
      //     { label: '离异', value: '离异' },
      // ],
      startDate: '',
      endDate: '',

      Name: '蔡健铃',
      Sex: '',
      CreateUserName: '',
      phone: '',
      Marriage: '', //婚姻状况
      email: '',
      city: '',
      birthday: '2019-03-12',
      school: '',
      posts: '', //职位
      source: undefined, //来源
      arr_source: ['boss', '58', '51job', '转介绍'],
      province: undefined, //籍贯
      arr_province: __arr_province(),
      // [
      //     "河北省","山西省","辽宁省","吉林省","黑龙江省","江苏省","浙江省","安徽省","福建省","江西省","山东省","河南省","湖北省","湖南省","广东省","海南省","四川省","贵州省","云南省","陕西省","甘肃省","青海省","台湾省","北京市","天津市","上海市","重庆市","内蒙古自治区","广西壮族自治区","宁夏回族自治区","新疆维吾尔自治区","西藏自治区","香港特别行政区","澳门特别行政区",
      // ],
      experience: undefined, //经验
      arr_experience: __arr_experience(), //['应届生','1年以内','1-3年','3-5年','5年以上'],
      Education: undefined,
      speciality: '', //专业
      arr_Education: __arr_Education(), // ['小学','大专','本科','研究生','硕士及以上'],
      // 求职意向
      address: '', //工作地址
      money: '', //薪资
      arr_worktime: __arr_worktime(), //['随时','一周','15天','一月','待定'],
      worktime: undefined, //到岗时间
      typeClass: '', //行业
      // 工作经历
      workNames: '',
      Remark: '',
      posts_Work: '',
      workDate: [],

      //项目经验
      ProjectName: '',
      CompanyNames: '',
      position: '',
      timeRanges: [],
      Intro: '',

      //简历附件
      resume_arr: []
    }
  },
  created () {
    let that = this
    that.Id = that.$route.query.id
    // console.log(this.Id);
    that.ajaxCVfn()
    //获取简历附件
    that.$axios.get(9964, { Pid: that.Id }, res => {
      if (res.data.code == 1) {
        that.resume_arr = res.data.data
      }
    })
  },

  methods: {
    showFn (index) {
      //打开收起
      console.log(this.showBool[index].bool)
      this.showBool[index].bool = !this.showBool[index].bool
      console.log(111, this.showBool[index].bool)
    },
    moment,
    workChange (value, dateStrings) {
      this.workDate = __dateTransation(value)
    },
    projectChange (value, dateStrings) {
      this.timeRanges = __dateTransation(value)
    },
    //获取标准简历信息
    ajaxCVfn () {
      let that = this
      that.$axios.get(9961, { Id: that.Id }, res => {
        if (!res.data) {
          return false
        }
        if (res.data.code == 1) {
          let personInfo = res.data.base
          that.Name = personInfo.Name
          that.Sex = personInfo.Sex ? personInfo.Sex : 1
          that.phone = personInfo.Mobile
          that.Marriage = personInfo.MaritalStatus
          that.email = personInfo.Email
          that.city = personInfo.CurrentResidence
          that.birthday = personInfo.Birthday
          that.posts = personInfo.Position
          that.CreateUserName = personInfo.CreateUserName
          that.province = personInfo.NativePlace
          that.address = personInfo.WorkPlace
          that.source = personInfo.Source
          that.Education = personInfo.Qualification
          that.experience = personInfo.WorkingLife
          that.school = personInfo.GraduatedFrom
          that.CreateUserName = personInfo.CreateUserName
          that.worktime = personInfo.ComeToPositionData
          that.money = personInfo.WorkingMoney
          that.typeClass = personInfo.ExpectIndustry
          that.speciality = personInfo.Speciality

          let arr_work = res.data.work_experience

          if (arr_work.length > 0) {
            for (let i = 0; i < arr_work.length; i++) {
              let obj = {}
              obj.Id = arr_work[i].Id
              obj.Position = arr_work[i].Position
              // obj.Pid = arr_work[i].Pid;
              obj.Intro = arr_work[i].Intro
              obj.CompanyName = arr_work[i].CompanyName
              obj.bool = true
              obj.StartDate = arr_work[i].StartDate
              obj.EndDate = arr_work[i].EndDate
              that.workInfo_obj.push(obj)
            }
          }
          let arr_project = res.data.project_experience
          if (arr_project.length > 0) {
            for (let i = 0; i < arr_project.length; i++) {
              let obj = {}
              obj.Id = arr_project[i].Id
              obj.Position = arr_project[i].Position
              // obj.Pid = arr_project[i].Pid;
              obj.Intro = arr_project[i].Intro
              obj.CompanyName = arr_project[i].CompanyName
              obj.bool = true
              obj.StartDate = arr_project[i].StartDate
              obj.EndDate = arr_project[i].EndDate
              obj.ProjectName = arr_project[i].ProjectName
              that.projectinfo_obj.push(obj)
            }
          }
          sessionStorage.setItem('WORKINFO', JSON.stringify(that.workInfo_obj))
          sessionStorage.setItem(
            'PROJECTINFO',
            JSON.stringify(that.projectinfo_obj)
          )
          // that.addWork('type');
        } else {
          that.$message.error(res.data.msg)
        }
      })
    },
    birthdayChange (value) {
      let time = value._d
      let date1 = new Date(time)
      this.birthday =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
    },
    // 限制生日选择的范围
    disabledDate (current) {
      return current && current > moment().endOf('day')
    },
    //项目经验时间处理
    projectChangeTime (value, index) {
      // console.log(index, this.projectinfo_obj,)
      // console.log( this.projectinfo_obj[index])
      this.projectinfo_obj[index].StartDate = __dateTransation(value)[0]
      this.projectinfo_obj[index].EndDate = __dateTransation(value)[1]
    },
    //项目经验时间处理
    // projectTransTime(value){
    //    let arr = __dateTransation(value)
    // },
    //工作经历时间处理
    workChangeTime (value, index) {
      console.log('下标', index)
      console.log(value)
      let arr = __dateTransation(value)
      this.workInfo_obj[index].StartDate = arr[0]
      this.workInfo_obj[index].EndDate = arr[1]
    },
    // 时间范围选择
    changeTime (value) {
      let arr = __dateTransation(value)
      this.endDate = arr[1]
      this.startDate = arr[0]
    },
    //简历上传
    handleRemove (file) {
      // const index = this.fileList.indexOf(file);
      // const newFileList = this.fileList.slice();
      // newFileList.splice(index, 1);
      // this.fileList = newFileList;
      // console.log(newFileList)
    },
    //简历上传
    beforeUpload (file) {
      // console.log(file)
      if (file.type == 'text/html') {
        that.$message.error('换成word或者pdf格式')
        return false
      }
      let that = this
      this.__uploadFile__([file]).then(res => {
        //  that.info.Photo = res[0].FileUrl;
        let obj = res[0]
        if (!obj) {
          that.$message.error('简历上传失败!')
          return false
        }
        that.upFlieFn(obj)
      })
      return false
    },
    upFlieFn (obj) {
      let that = this
      let type = __fileUpPublicFn(obj)
      let objData = {
        Pid: that.Id,
        CreateUserId: that.__USER__.uid,
        FileUrl: obj.FileUrl,
        FileName: obj.FileName,
        FileType: type
      }
      that.$axios.post(9962, objData, res => {
        if (res.data.code == 1) {
          objData.Id = res.data.data.Id
          that.resume_arr.push(objData)
          that.$message.success('添加成功')
        } else {
          that.$message.error(res.data.msg)
        }
      })
    },
    // 简历显示隐藏
    CVHideFn (num) {
      for (let i = 0; i < this.CV_arr_show.length; i++) {
        if (this.CV_arr_show[i] == num) {
          this.CV_arr_show.splice(i, 1)
          return false
        }
      }
    },
    CVShowFn (num) {
      let k = undefined
      for (let i = 0; i < this.CV_arr_show.length; i++) {
        if (this.CV_arr_show[i] == num) {
          k = num
        }
      }
      if (!k) {
        this.CV_arr_show.push(num)
      }
    },
    //   删除简历
    CVDelFn (id, k) {
      //   console.log('删除',id);
      let that = this
      that.$axios.post(9963, { Id: id }, res => {
        if (res.data.code == 1) {
          that.resume_arr.splice(k, 1)
        }
      })
    },
    //婚姻状况
    onMarriageChange (e) {
      console.log('radio2 checked', e.target.value, this.Marriage)
    },
    //选籍贯
    handleChange (value) {
      console.log(`selected ${value}`)
    },
    handleBlur () {
      console.log('blur')
    },
    handleFocus () {
      console.log('focus')
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    //v-model 时间
    changeInput () {
      //   console.log(sessionStorage.getItem("WORKINFO"));
      //   console.log(this.workInfo_obj);
    },
    //取消
    cancelFn (k, type) {
      //   console.log(k)
      //   console.log(JSON.parse(sessionStorage.getItem('WORKINFO')));
      //   console.log(JSON.parse(sessionStorage.getItem('PROJECTINFO')))
      let that = this
      if (type == 'work') {
        let WORK = JSON.parse(sessionStorage.getItem('WORKINFO'))
        that.workInfo_obj = []
        for (let i = 0; i < WORK.length; i++) {
          that.workInfo_obj.push(WORK[i])
        }
      } else {
        let PROJECT = JSON.parse(sessionStorage.getItem('PROJECTINFO'))
        that.projectinfo_obj[k] = []
        that.projectinfo_obj = []
        for (let i = 0; i < PROJECT.length; i++) {
          that.projectinfo_obj.push(PROJECT[i])
        }
      }
    },
    //删除工作经历or项目经验
    removeDel (k, id, type) {
      let that = this
      that.spinning = true
      that.$axios.post(9949, { Id: id }, res => {
        that.spinning = false
        if (res.data.code == 1) {
          if (type == 'project') {
            that.projectinfo_obj.splice(k, 1)
            let PROJECT = JSON.parse(
              sessionStorage.getItem('PROJECTINFO')
            ).splice(k, 1)
            sessionStorage.setItem('PROJECTINFO', JSON.stringify(PROJECT))
          } else {
            that.workInfo_obj.splice(k, 1)
            let WORK = JSON.parse(sessionStorage.getItem('WORKINFO')).splice(
              k,
              1
            )
            sessionStorage.setItem('WORKINFO', JSON.stringify(WORK))
          }
          that.$message.success('删除成功')
        } else {
          that.$message.error(res.data.msg)
        }
      })
    },
    //更新工作经历
    changeWork (k) {
      let that = this
      //  console.log(that.workInfo_obj[k]);
      that.spinning = true
      let obj = that.workInfo_obj[k]
      //  delete obj.Pid;
      delete obj.bool
      that.$axios.post(9948, obj, res => {
        that.spinning = false
        if (res.data.code == 1) {
          obj.bool = true
          that.workInfo_obj.push(obj)
          // WORK.push(obj);
          let WORK = JSON.parse(sessionStorage.getItem('WORKINFO')).push(obj)
          WORK.splice(k, 1)
          sessionStorage.setItem('WORKINFO', JSON.stringify(WORK))
          that.workInfo_obj.splice(k, 1)
          that.$message.success('更新成功')
        } else {
          that.$message.error(res.data.msg)
        }
      })
    },
    //工作经历
    workSubmit (e) {
      let that = this

      if (!that.workNames) {
        that.$message.error('公司名称不能为空!')
        return false
      }
      if (!that.Remark) {
        that.$message.error('描述为必填!')
        return false
      }
      if (!that.posts_Work) {
        that.$message.error('任职岗位为必填!')
        return false
      }
      that.spinning = true
      // let arr = __dateTransation(that.workDate);
      let obj = {
        Pid: that.Id, //简历Id	是	[string]
        StartDate: that.workDate[0], //开始时间	是	[string]
        EndDate: that.workDate[1], //结束时间	是	[string]
        CompanyName: that.workNames, //公司名称	是	[string]
        Intro: that.Remark,
        Position: that.posts_Work
      }
      that.$axios.post(9959, obj, res => {
        that.spinning = false
        if (res.data.code == 1) {
          obj.bool = true
          obj.Id = res.data.data.Id
          that.projectinfo_obj.push(obj)
          let WORK = JSON.parse(sessionStorage.getItem('WORKINFO')).push(obj)
          sessionStorage.setItem('WORKINFO', JSON.stringify(WORK))
          that.$message.success('保存成功')
          that.workDate = []
          that.posts_Work = ''
          that.workNames = ''
          that.Remark = ''
        } else {
          that.$message.error(res.data.msg)
        }
      })
    },
    //更新项目经历
    changeProject (k) {
      let that = this
      // console.log((that.projectinfo_obj)[k])
      that.spinning = true
      let obj = that.projectinfo_obj[k]
      // delete obj.Pid;
      delete obj.bool
      that.$axios.post(9947, obj, res => {
        that.spinning = false
        let info = res.data
        if (info.code == 1) {
          obj.bool = true
          that.projectinfo_obj.push(obj)
          let PROJECT = JSON.parse(sessionStorage.getItem('PROJECTINFO')).push(
            obj
          )
          PROJECTINFO.splice(k, 1)
          sessionStorage.setItem('PROJECTINFO', JSON.stringify(PROJECT))
          that.projectinfo_obj.splice(k, 1)
          that.$message.success('更新成功')
        } else {
          that.$message.error(res.data.msg)
        }
      })
    },
    //项目经验
    projectSubmit () {
      let that = this
      console.log(that.projectInfo)
      if (!that.CompanyNames) {
        that.$message.error('公司名称不能为空!')
        return false
      }
      if (!that.ProjectName) {
        that.$message.error('项目名不能为空!')
        return false
      }
      if (!that.Intro) {
        that.$message.error('描述为必填!')
        return false
      }
      if (!that.position) {
        that.$message.error('任职岗位为必填!')
        return false
      }
      that.spinning = true
      // let arr = __dateTransation(that.timeRanges);
      let obj = {
        Pid: that.Id, //简历Id	是	[string]
        StartDate: that.timeRanges[0], //开始时间	是	[string]
        EndDate: that.timeRanges[1], //结束时间	是	[string]
        CompanyName: that.CompanyNames, //公司名称	是	[string]
        Intro: that.Intro,
        Position: that.position,
        ProjectName: that.ProjectName
      }
      that.$axios.post(9960, obj, res => {
        that.spinning = false
        if (res.data.code == 1) {
          obj.bool = true
          obj.Id = res.data.data.Id
          that.projectinfo_obj.push(obj)
          let PROJECT = JSON.parse(sessionStorage.getItem('PROJECTINFO')).push(
            obj
          )
          sessionStorage.setItem('PROJECTINFO', JSON.stringify(PROJECT))
          that.$message.success('提交成功')
          that.Intro = ''
          that.timeRanges = []
          that.position = ''
          that.CompanyNames = ''
          that.ProjectName = ''
        } else {
          that.$message.error(res.data.msg)
        }
      })
    },
    //提交标准简历
    subCVBtn () {
      let that = this
      let obj = {
        Id: that.Id,
        // Name: that.Name,
        // Sex: that.Sex,    //简历的Id	是	[string]
        Mobile: that.phone, //手机号	是	[string]
        Email: that.email, //邮箱	是	[string]
        NativePlace: that.province, //籍贯	是	[string]
        Qualification: that.Education, //学历	是	[string]
        GraduatedFrom: that.school, //毕业于	是	[string]
        CurrentResidence: that.city, //现居地	是	[string]
        Source: that.source, //来源	是	[string]
        WorkingLife: that.experience, //工作年限	是	[string]
        CreateUserId: that.__USER__.uid, //添加人	是	[string]-------
        // CreateUserName: that.__USER__.name,	 //添加人	是	[string]
        WorkPlace: that.address, // 期望工作地点	是	[string]
        ComeToPositionData: that.worktime, //到岗日期	是	[string]
        ExpectIndustry: that.typeClass, //期望行业	是	[string]
        MaritalStatus: that.Marriage, //婚姻状态	是	[string]
        WorkingMoney: that.money, // 薪资
        Speciality: that.speciality, //专业
        Position: that.posts, //应聘职位
        Birthday: that.birthday //出生日期
      }
      that.$axios.post(9958, obj, res => {
        if (!res.data) {
          return false
        }
        if (res.data.code == 1) {
          that.$message.success('添加成功')
          obj.CurrentResidence = that.city
          let arr = __arr_Education()
          obj.CreateUserName = that.__USER__.name
          for (let i = 0; i < arr.length; i++) {
            if (obj.Qualification == arr[i].value) {
              obj.Qualification = arr[i].name
              obj.Sex = obj.Sex ? obj.Sex : 1
              obj.Name = that.Name
              obj.Photo = this.$store.state.app.personInfo.Photo
              // this.$store.state.app.personInfo = {};
              this.$store.state.app.personInfo = obj
              console.log(obj, 2222222222)
              // this.$store.dispatch('PERSON_INFO', obj)
            }
          }
        } else {
          that.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>
<style lang="less">
.resume {
  font-size: 14px;
  .liner {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 50%;
      width: 100%;
      height: 0px;
      border-top: 1px solid #e8e8e8;
    }
    .zIndex {
      background-color: #ffffff;
      z-index: 10;
      padding-left: 12px;
      position: relative;
    }
    .addtitle {
      margin: 12px 0px 12px 50%;
      transform: translateX(-50%);
      display: inline-block;
      padding-right: 12px;
    }
  }
  .flexBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .flexLeft {
    min-width: 100px;
    width: 100px;
  }
  .area {
    a {
      color: #999999;
      text-decoration: none;
    }
  }
  .fileName {
    min-width: 200px;
    display: inline-block;
  }
  .lookView {
    margin-top: -80px;
  }
  .lineHeight {
    padding: 6px 0px 10px 0px;
    border-bottom: 1px solid #e8e8e8;
  }
  .ctrlView {
    overflow: hidden;
    position: relative;
    .closeBtn {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 40px;
    }
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    line-height: 1.1;
    margin: 1px 0px 10px;
    .iconSex {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: inline-block;
      font-size: 8px;
      text-align: center;
      line-height: 18px;
      color: #ffffff;
      margin-top: -3px;
      vertical-align: bottom;
    }
  }
  // .ant-form-item-label{text-align:left !important;}
  i:hover {
    cursor: pointer;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
}
</style>
